@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

body {
  margin: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.react-datepicker__header__dropdown{
  display: flex;
  justify-content: space-evenly;
}

.react-datepicker-wrapper{
  width: auto !important;
}

.MuiTabs-root{
  max-width: 50vw;
}

.ProseMirror{
  min-height: 170px;
  background-color: white !important;
}

.RaFileInput-dropZone{
  border: 1px dashed #c4c4c4
}